<template>
  <div>
    <router-view />
  </div>
</template>

<script>
export default {
  name: "VueWeatherApp",

  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="less" scoped>
@import url("./static/index.css");

</style>