import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    city: "",
    date: "",
    fengli: "",
    fengxiang: "",
    high: "",
    low: "",
    type: "",
    ganmao:'',
    val:[]
   
  },
  mutations: {
    OPEN(state, val) {
      console.log(val);
      state.city=val.city,
      state.ganmao=val.ganmao,
      state.date =  val.val[0].date
      state.fengli =  val.val[0].fengli
      state.fengxiang = val.val[0].fengxiang
      state.high =  val.val[0].high
      state.low =  val.val[0].low
      state.type =  val.val[0].type
      state.week =  val.val[0].week
      // state.date =  val.date
      // state.fengli =  val.fengli
      // state.fengxiang = val.fengxiang
      // state.high =  val.high
      // state.low =  val.low
      // state.type =  val.type
      state.val= val.val
    },

  },
  actions: {
  },
  modules: {
  }
})
