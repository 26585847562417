<template>
  <div>
    <p><span>最低{{low}}</span> <span>最高{{high}}</span></p>
    <p><span>风向</span> <span>{{fengxiang}}</span></p>
    <p><span>风速</span> <span>{{fengli}}</span></p>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "VueWeatherRightWetherItema",

  data() {
    return {};
  },
computed:{
    ...mapState( ['high','low','fengli','fengxiang']),

},
  mounted() {},

  methods: {},
};
</script>

<style lang="less" scoped>
div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #1e2025;
}
p {
  flex: 1;
  margin: 5px 10px;
  line-height: 25px;
  // background-color: purple;
}
p > span:nth-child(1) {
  float: left;
}
p > span:nth-child(2) {
  float: right;
}
</style>