<template>
  <div class="home">
    <div>
      <VueWeatherLeftWether />
    </div>
    <div class="item">
      <div>
        <VueWeatherRightWetherItema />
      </div>
      <div><VueWeatherRightWetherItemb /></div>
      <div>
        <VueWeatherRightWetherSerch />
      </div>
    </div>
  </div>
</template>

<script>
import VueWeatherLeftWether from "../components/Left_wether.vue";
import VueWeatherRightWetherItema from "../components/Right_wether_itema.vue";
import VueWeatherRightWetherItemb from "../components/Right_wether_itemb.vue";
import VueWeatherRightWetherSerch from "../components/Right_wether_serch.vue";
import { GetweatherAPI } from "@/api/api.js";
export default {
  name: "VueWeatherHome",
  components: {
    VueWeatherLeftWether,
    VueWeatherRightWetherItema,
    VueWeatherRightWetherItemb,
    VueWeatherRightWetherSerch,
  },

  data() {
    return {
      city: "武汉",
      tip: "你若安好，便是晴天~ 现在的温度比较舒适~"
    };
  },

  mounted() {
    //得到tip
    GetweatherAPI({
      city: this.city,
    }).then((res2) => {
      this.tip = res2.data.info.tip
      console.log(this.tip, 666)
    })
    //具体数据
    GetweatherAPI({
      city: this.city,
      type: "week"
    }).then((res) => {
      console.log(res);
      console.log(this.tip, 888)
      const date = {
        city: this.city,
        //ganmao: res.data.data[0].tip,
        ganmao: this.tip,
        val: res.data.data,
      };
      console.log(date);
      // state.date =  val.val[0].date
      // state.fengli =  val.val[0].fengli
      // state.fengxiang = val.val[0].fengxiang
      // state.high =  val.val[0].high
      // state.low =  val.val[0].low
      // state.type =  val.val[0].type


      this.$store.commit("OPEN", date);
    });
  },
  computed: {},
  methods: {},
};
</script>

<style lang="less" scoped>
.home {
  display: flex;
  width: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
  height: 400px;
  border-radius: 10px;
  overflow: hidden;
}
.home > div:nth-child(1) {
  width: 220px;
  padding: 10px;
  background: -webkit-linear-gradient(left top, #74bacb, #4a54c1);
}
.home > div:nth-child(2) {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex: 1;
  background-color: #232631;
}
.item {
  display: flex;
  padding: 20px;
  flex-direction: column;
}
.item div {
  flex: 1;
  width: 100%;
  border-radius: 10px;
  margin: 5px 0;
  color: #fff;
}
</style>