<template>
  <div>
    <p>
      <input
        type="text"
        placeholder="输入城市"
        v-model="val"
        @keyup.enter="enter(val)"
      />
    </p>
    <p>
      <button @click="enter(val)">
        <span class="iconfont">&#xf0205;</span>查询
      </button>
    </p>
  </div>
</template>

<script>
import { GetweatherAPI } from "@/api/api.js";

export default {
  name: "VueWeatherRightWetherSerch",

  data() {
    return {
      val: "武汉市",
      tips: "光芒透过云缝，洒向大地~ 现在的温度比较凉爽~"
    };
  },

  mounted() {},

  methods: {
    enter(val) {
      //得到tip
      GetweatherAPI({
        city: val,
      }).then((res2) => {
        this.tip = res2.data.info.tip
        console.log(this.tip, 666)
      })
      console.log(this.tip, 999)
      GetweatherAPI({ city: val, type: "week" }).then((res) => {
        if (!res.data.success) {
          alert("请输入正确的城市名字");
        } else {
          const date = {
            // city: res.data.data.city,
            // ganmao: res.data.data.ganmao,
            // val: res.data.data.forecast,
            //光芒透过云缝，洒向大地~ 现在的温度比较凉爽~
            //你若安好，便是晴天~ 现在的温度比较舒适~
            //天暗下来，你就是阳光~ 天有点冷，注意保暖~
            city: res.data.city,
            ganmao: this.tip,
            // ganmao: "天暗下来，你就是阳光~ 天有点冷，注意保暖~",
            val: res.data.data,

          };
          this.$store.commit("OPEN", date);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
p {
  text-align: center;
  margin: 0;
  margin-top: 10px;
}
p > input {
  width: 45%;
  height: 35px;
  border-radius: 50px;
  border: none;
  outline: none;
  text-align: center;
}
p > input:focus {
  width: 80%;
}
p > button {
  cursor: pointer;
  margin-top: 5px;
  width: 45%;
  height: 40px;
  border-radius: 50px;
  border: none;
  font-weight: 700;
  font-size: 15px;
  background: -webkit-linear-gradient(left, #88ddf3, #5668e5);
}
p > button:hover {
  width: 85%;
}
</style>