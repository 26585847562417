<template>
  <div>
    <ul class="scroll-list">
      <li v-for="(item,index) in val" :key="index"  v-show="index<4" >
<!--      <li v-for="(item,index) in val" :key="index" >-->
         <!-- 晴天{晴} -->
      <span class="iconfont" v-show="item.type=='晴'">&#xe61f;</span>
      <!-- 多云{阴，多云}-->
      <span class="iconfont" v-show="item.type=='多云' || item.type=='阴' || item.type=='霾' " >&#xe61a;</span>
      <!-- 下雨{雨} -->
      <span class="iconfont" v-show="item.type=='小雨' || item.type=='中雨' ||item.type=='大雨' " >&#xe619;</span>
      <!-- 下雪 -->
      <span class="iconfont" v-show="item.type=='小雪' || item.type=='雨夹雪' || item.type=='中雪' || item.type=='大雪' || item.type=='暴雪'" >&#xe623;</span>
        <h3>{{item.week}}</h3>
        <p>{{item.type}}</p>
        <p>{{item.low}}~{{item.high}}</p>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "VueWeatherRightWetherItemb",

  data() {
    return {};
  },

computed:{...mapState(['val']),},
  mounted() {},

  methods: {},
};
</script>

<style lang="less" scoped>
ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  border-radius: 5px;
  //overflow: hidden;
  overflow-x: auto;
  white-space: nowrap;
}
ul li {
  flex: 1;
  width: 70px;
  padding:5px;
  text-align: center;
  background-color: #1e2025;
}
ul > li:nth-child(1) {
  color: #333;
  border-radius: 5px;

  background-color: #fff;
}
.iconfont {
  font-size: 20px;
}
h3,
p {
  margin: 10px 0;
  font-size: 14px;
}

//.scroll-list {
//  overflow-x: auto;
//  white-space: nowrap;
//}
//
//.scroll-list li {
//  display: inline-block;
//  padding: 10px;
//}
</style>