<template>
  <div class="left">
    <h2>{{week}}</h2>
    <p>{{time}}</p>
    <p>
      <span class="iconfont">&#xf0205;</span>
      <span>{{city}}</span>
    </p>
    <p class="ganmao">{{ganmao}}</p>
    <div class="item">
      <!-- 晴天{晴} -->
      <span class="iconfont" v-show="type=='晴'">&#xe61f;</span>
      <!-- 多云{阴，多云}-->
      <span class="iconfont" v-show="type=='多云' || type=='阴' || type=='霾' " >&#xe61a;</span>
      <!-- 下雨{雨} -->
      <span class="iconfont" v-show="type=='小雨' || type=='中雨' ||type=='大雨' " >&#xe623;</span>
      <!-- 下雪 -->
      <span class="iconfont" v-show="type=='小雪' || type=='中雪' ||type=='大雪' " >&#xe623;</span>

      <h2>{{high}}</h2>
      <p>天气为 <span class="tq">{{type}}</span></p>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "VueWeatherLeftWether",
  data() {
    return {
      time:''
    };
  },
  computed: {
    ...mapState(['week','city','date','type','high','ganmao']),
  },
  mounted() {
   function nowTime() {//获取当前时间
    let now= new Date();
    let _month = ( 10 > (now.getMonth()+1) ) ? '0' + (now.getMonth()+1) : now.getMonth()+1;
    let _day = ( 10 > now.getDate() ) ? '0' + now.getDate() : now.getDate();
    return now.getFullYear() + '-' + _month + '-' + _day 
}

let times=nowTime()
this.time=times
  },

  methods: {},
};
</script>

<style lang="less" scoped>
.left {
  color: #fff;
}
h2,
p {
  margin: 10px 0;
}
.item .iconfont {
  font-size: 50px;
  font-weight: 700;
}
.item {
  position: fixed;
  left: 15px;
  bottom: 15px;
}
.item h2 {
  font-size: 50px;
}
.tq{
  color: #333;
}
.gnamao{
  font-size: 14px;
}
</style>